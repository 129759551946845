<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">设备管理</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item style="float: right; margin-right: 0">
					<a-button type="primary" html-type="submit">
						查询
					</a-button>
					<a-button @click="Reset">重置</a-button>
					<a-button html-type="submit" @click="exportDevice">
						导出
					</a-button>
				</a-form-model-item>
				<a-form-model-item label="设备类型">
					<a-select v-model="query.class_type" class="a-select" placeholder="请选择类型" @change="handleChangeClass">
						<a-select-option :value="0">
							{{ '全部' }}
						</a-select-option>
						<a-select-option :value="1">
							{{ '消防' }}
						</a-select-option>
						<a-select-option :value="2">
							{{ '监控' }}
						</a-select-option>
						<a-select-option :value="3">
							{{ '门禁' }}
						</a-select-option>
						<a-select-option :value="4">
							{{ '网络设备' }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="二级类型">
					<a-select class="a-select" placeholder="请先选择分类" v-model="query.dictionary_id" @change="typeChange">
						<a-select-option :value="item.dictionary_id" v-for="item in typelsitQuery"
							:key="item.dictionary_id">
							{{ item.device_type_name }}
						</a-select-option>
					</a-select>
					<!-- {{ lc_label }} -->
				</a-form-model-item>
				<a-form-model-item label="设备名称">
					<a-input v-model="query.device_name" placeholder="设备名称" />
				</a-form-model-item>
				<a-form-model-item label="设备编号">
					<a-input v-model="query.device_number" placeholder="设备编号" />
				</a-form-model-item>
			</a-row>
			<a-row>
				<a-form-model-item label="打点状态">
					<a-select v-model="query.bind_type" class="a-select" placeholder="请选择" @change="handleChangebind">
						<a-select-option :value="0">
							{{ '全部' }}
						</a-select-option>
						<a-select-option :value="1">
							{{ '未打点' }}
						</a-select-option>
						<a-select-option :value="2">
							{{ '已打点' }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
			</a-row>
		</a-form-model>

		<a-table class="main-table" :pagination="{
			current: query.page,
			total: pagetotal
		}" @change="listChange" :columns="columns" :data-source="data" rowKey="id" style="margin-top: 14px" v-viewer>
			<span slot="class_type" slot-scope="text, record">
				{{ text == 1 ? '消防' : (text == 2 ? '监控' : (text == 3 ? '门禁' : '网络设备')) }}
			</span>
			<span slot="icon" slot-scope="text, record">
				<img :src="$domain + record.icon" class="img-qr">
			</span>
			<span slot="status" slot-scope="text, record">
				<div class="img-box" v-if="record.status == 1">
					<img :src="$domain + record.icon" class="img-qr img-icon" style="filter: drop-shadow(#7AF4FF 100px 0)">
				</div>
				<div class="img-box" v-else>
					<img :src="$domain + record.icon" class="img-qr img-icon" style="filter: drop-shadow(#656565 100px 0)">
				</div>
			</span>
			<span slot="area" slot-scope="text, record">
				<a-space>
					<template v-if="record.is_bind == 1">{{ factory_label[record.factory_class] }}>{{ record.build_name
					}}>{{
	record.floor_id
}}</template>
					<template v-if="record.is_bind != 1">——</template>
				</a-space>
			</span>
			<span slot="action" slot-scope="text, record">
				<a-space>
					<a @click="toEdit(record)">编辑</a>
					<a @click="toRelation(record)">关联摄像机</a>
				</a-space>
			</span>
		</a-table>

		<div>
			<a-drawer title="编辑设备" placement="right" width="750" :closable="false" :visible="visible" @close="onClose">
				<a-form-model :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" ref="dictionaryForm" :model="form"
					:rules="dictionaryRules">
					<a-form-model-item label="所在位置">
						<template v-if="form.is_bind == 1">{{ factory_label[form.factory_class] }}>{{ form.build_name
						}}>{{
	form.floor_id
}}</template>
						<template v-else>暂未绑定</template>
					</a-form-model-item>
					<a-form-model-item prop="class_type" label="设备类型">
						<a-select v-model="form.class_type" class="a-select" placeholder="请选择类型" @change="handleChangeEdit">
							<a-select-option :value="1">
								{{ '消防' }}
							</a-select-option>
							<a-select-option :value="2">
								{{ '监控' }}
							</a-select-option>
							<a-select-option :value="3">
								{{ '门禁' }}
							</a-select-option>
							<a-select-option :value="4">
								{{ '网络设备' }}
							</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item label="二级类型" prop="dictionary_id">
						<a-select class="a-select" placeholder="请先选择分类" v-model="form.dictionary_id">
							<a-select-option :value="item.dictionary_id" v-for="item in typelsit" :key="item.dictionary_id">
								{{ item.device_type_name }}
							</a-select-option>
						</a-select>
						<!-- {{ lc_label }} -->
					</a-form-model-item>
					<a-form-model-item prop="device_number" label="设备编号">
						<a-input v-model="form.device_number" disabled></a-input>
					</a-form-model-item>
					<a-form-model-item label="展示级别">
						<a-select v-model="form.low_show" style="width: 120px" :disabled="isDo">
							<a-select-option :value="((count + 33) * 0.5)" v-for="count in 11" :key="((count + 33) * 0.5)">
								{{ ((count + 33) * 0.5) }}
							</a-select-option>
						</a-select>
						<span class="ant-form-text">
							<a style="color: #D9D9D9">—</a>
						</span>
						<a-select v-model="form.high_show" style="width: 120px" :disabled="isDo">
							<a-select-option :value="((count + 33) * 0.5)" v-for="count in 11" :key="((count + 33) * 0.5)">
								{{ ((count + 33) * 0.5) }}
							</a-select-option>
						</a-select>
						<a-switch :checked="form.warn_show == 1" @change="warnChange" :disabled="isDo">
							<a-icon slot="checkedChildren" type="check" />
							<a-icon slot="unCheckedChildren" type="close" />
						</a-switch>
						仅报警显示
						<a-switch :checked="form.is_customize == 1" @change="onlyOnChange" />
						自定义编辑
					</a-form-model-item>
					<a-form-model-item prop="device_name" label="设备名称">
						<a-input v-model="form.device_name" placeholder="请输入设备名"></a-input>
					</a-form-model-item>
					<a-form-model-item prop="lon" label="经度">
						<a-input v-model="form.lon" placeholder="请输入经度" disabled></a-input>
					</a-form-model-item>
					<a-form-model-item prop="lat" label="纬度">
						<a-input v-model="form.lat" placeholder="请输入纬度" disabled></a-input>
					</a-form-model-item>
					<a-form-model-item prop="device_number" label="设备报警">
						<a-radio-group name="radioGroup" v-model="form.warn_type">
							<a-radio :value="1">
								语音加页面报警
							</a-radio>
							<a-radio :value="2">
								页面报警
							</a-radio>
							<a-radio :value="3">
								不报警
							</a-radio>
						</a-radio-group>
					</a-form-model-item>
					<a-form-model-item prop="remark" label="位置描述">
						<a-textarea v-model="form.remark" placeholder="请输入位置描述">
						</a-textarea>
					</a-form-model-item>
				</a-form-model>
				<div :style="{
					position: 'absolute',
					bottom: 0,
					width: '100%',
					borderTop: '1px solid #e8e8e8',
					padding: '10px 16px',
					textAlign: 'right',
					left: 0,
					background: '#fff',
					borderRadius: '0 0 4px 4px',
				}">
					<a-button style="marginRight: 8px" @click="onClose">
						取消
					</a-button>
					<a-button type="primary" @click="submitInfo">
						提交
					</a-button>
				</div>
			</a-drawer>
		</div>


		<!-- 关联抽屉 -->
		<a-drawer title="关联摄像机" placement="right" width="750" :closable="false" :visible="relationvisible"
			@close="relationClose">
			<a-form-model class="form-right" :colon="false" layout="inline" :model="relationquery" labelAlign="left"
				@submit="relationHandleSearch" @submit.native.prevent>
				<a-row>
					<a-form-model-item label="所在位置">
						<template v-if="relationInfo.is_bind == 1">{{ factory_label[relationInfo.factory_class] }} >
							{{
								relationInfo.floor_id
							}}>{{ relationInfo.build_name }}</template>
						<template v-else>暂未绑定</template>
					</a-form-model-item>
				</a-row>
				<a-row>
					<a-form-model-item label="设备编号">
						{{ relationInfo.device_number }}
					</a-form-model-item>
				</a-row>
				<a-row>
					<a-form-model-item style="float: right; margin-right: 0">
						<a-button type="primary" html-type="submit" size="small">
							查询
						</a-button>
						<a-button @click="relationReset" size="small" style="margin-left: 10px;">重置</a-button>
					</a-form-model-item>
					<a-form-model-item label="设备名称">
						<a-input v-model="relationquery.device_name" placeholder="设备名称" size="small" />
					</a-form-model-item>
					<a-form-model-item label="设备编号">
						<a-input v-model="relationquery.device_number" placeholder="设备编号" size="small" />
					</a-form-model-item>
				</a-row>
				<a-row>
					<a-tabs v-model="relationquery.type" @change="callback">
						<a-tab-pane :key="1" tab="已关联">
						</a-tab-pane>
						<a-tab-pane :key="2" tab="未关联" force-render>
						</a-tab-pane>
					</a-tabs>
				</a-row>
			</a-form-model>
			<a-empty v-show="relationData.length == 0">
				<span slot="description">暂无关联摄像头 </span>
				<a-button type="primary" @click="changeRelation">
					去关联
				</a-button>
			</a-empty>
			<a-table v-show="relationData.length != 0" class="main-table" :pagination="{
				current: relationquery.page,
				total: relationpagetotal
			}" @change="relationListChange" :columns="relationColumns" :data-source="relationData" rowKey="id"
				style="margin-top: 14px" v-viewer>
				<span slot="class_type" slot-scope="text, record">
					{{ text == 1 ? '消防' : (text == 2 ? '监控' : (text == 3 ? '门禁' : '网络设备')) }}
				</span>
				<span slot="icon" slot-scope="text, record">
					<img :src="record.icon" class="img-qr">
				</span>
				<span slot="action" slot-scope="text, record">
					<a size="small" v-if="relationquery.type == 2" @click="relation(record)">关联</a>
					<a size="small" v-else @click="unRelation(record)" style="color: #000000;">解除</a>
				</span>
			</a-table>
		</a-drawer>

	</div>
</template>

<script>
import {
	domain
} from '@/siteInfo.js'
import { get } from 'js-cookie'
import { type } from 'os'

export default {
	data() {
		return {
			label: '',
			query: {
				page: 1,
				limit: 10
			},
			typelsit: [],
			relation_device_id: null,
			visible: false,
			relationvisible: false,
			relationquery: {
				page: 1,
				limit: 10,
				type: 1
			},
			relationInfo: {},
			relationpagetotal: 0,
			factory_label: {
				'1': '大理卷烟厂复烤厂',
				'2': '大理卷烟厂主厂区',
				'3': '大理卷烟厂斜阳库'
			},
			floor_label: {
				'1': 'F1',
				'2': 'F2',
				'3': 'M',
				'4': 'F3',
				'5': 'F4',
				'6': 'F5'
			},
			relationData: [],
			relationColumns: [
				{
					title: '设备类型',
					dataIndex: 'class_type',
					scopedSlots: {
						customRender: 'class_type'
					}
				},
				// {
				// 	title: '设备类型',
				// 	dataIndex: 'device_type_name'
				// },
				{
					title: '设备名称',
					dataIndex: 'device_name'
				},
				{
					title: '设备编号',
					dataIndex: 'device_number'
				},
				{
					title: '操作',
					scopedSlots: {
						customRender: 'action'
					},
					// width: "20%"
				}
			],
			fromDictionary: '',
			pagetotal: 0,
			checkValue: [],
			typelsitQuery: [],
			checkOptions: [{
				label: '开启',
				value: 1
			},
			{
				label: '关闭',
				value: -1
			}
			],
			columns: [{
				title: '序号',
				customRender: (text, record, index) => index + 1
			},
			{
				title: '设备类型',
				dataIndex: 'class_type',
				scopedSlots: {
					customRender: 'class_type'
				}
			},
			{
				title: '设备二级类型',
				dataIndex: 'device_type_name'
			},
			{
				title: '设备名称',
				dataIndex: 'device_name'
			},
			{
				title: '设备编号',
				dataIndex: 'device_number'
			},
			{
				title: '所在位置',
				scopedSlots: {
					customRender: 'area'
				}
			},
			{
				title: '设备icon',
				scopedSlots: {
					customRender: 'icon'
				}
			},
			{
				title: '设备状态',
				dataIndex: 'status',
				scopedSlots: {
					customRender: 'status'
				}
			},
			{
				title: '操作',
				scopedSlots: {
					customRender: 'action'
				}
			}
			],
			data: [],
			form: {},
			confirmLoading: false,
			dictionaryRules: {
				class_type: [
					{
						required: true,
						message: '请选择类型',
						trigger: 'blur'
					}
				],
				dictionary_id: [
					{
						required: true,
						message: '请选择二级类型',
						trigger: 'blur'
					}
				],
				device_name: [
					{
						required: true,
						message: '设备名不能为空',
						trigger: 'blur'
					}
				],
			},
		}
	},
	created() {
		this.getList()
	},
	methods: {
		Reset() {
			this.query = {
				page: 1,
				limit: this.query.limit,
			}
			this.getList()
		},
		warnChange(checked) {
			if (checked) {
				this.form.warn_show = 1
			} else {
				this.form.warn_show = -1
			}
		},
		handleChangeEdit(value) {
			this.getTypeList(value)
			delete this.form.dictionary_id
		},
		onlyOnChange(checked) {
			//如果选中
			if (checked) {
				this.form.is_customize = 1
			} else {
				//需要重新渲染一下东西把
				this.$post(domain + '/admin/v1/dictionary/info', { 'dictionary_id': this.form.dictionary_id })
					.then(res => {
						let { code, msg, data } = res
						if (code == 0) {
							this.form.warn_show = data.warn_show
							this.form.low_show = data.low_show
							this.form.high_show = data.high_show
						} else {
							this.$message.error(msg, 1.5)
						}
					})
					.catch(err => {
					})
				this.form.is_customize = -1
			}
		},
		toEdit(item) {
			console.log('item', item)
			this.visible = true;
			this.form = item
			console.log('form', this.form)
			this.getTypeList(item.class_type)
			console.log(1231, item.class_type)
		},
		getTypeList(class_type) {
			this.$post(domain + '/admin/v1/dictionary/deviceTypeNameSelect', { 'class_type': class_type })
				.then(res => {
					let { code, msg, data } = res
					if (code == 0) {
						this.typelsit = data
						console.log('typelist', this.typelsit)
					} else {
						this.$message.error(msg, 1.5)
					}
				})
				.catch(err => {
				})
		},
		toChangeList(id) {
			this.$router.push({
				path: '/changeList',
				query: {
					id: id,
				}
			})
		},
		toContrastList(id) {
			this.$router.push({
				path: '/contrastList',
				query: {
					id: id,
				}
			})
		},
		getList() {
			this.$post(domain + '/admin/v1/device/list', this.query).then(res => {
				if (res.code === 0) {
					this.data = res.data.list
					this.pagetotal = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		listChange(e) {
			this.query.page = e.current
			this.getList();
		},
		handleSearch(query) {
			this.query.page = 1
			this.getList();
		},
		onClose() {
			this.visible = false;
		},
		handleHead(res) {
			this.uploading = true
			if (res.file.status == "done") {
				this.uploading = false
				if (res.file.response.code != 0) {
					this.$message.error(res.file.response.msg)
				} else {
					this.form.icon = res.file.response.data.really_path;
				}
			}
		},
		submitInfo() {
			console.log(this.form)
			this.$refs.dictionaryForm.validate(valid => {
				if (valid) {
					this.confirmLoading = true
					this.$post("device/update", this.form)
						.then(res => {
							let { code, msg, data } = res
							this.confirmLoading = false
							if (code == 0) {
								this.$message.success(msg, 1.5)
								this.visible = false
								this.getList()
							} else {
								this.$message.error(msg, 1.5)
							}
						})
						.catch(err => {
							this.confirmLoading = false
						})
				}
			})
		},
		handleChangeClass(value) {
			this.query.page = 1
			this.getTypeListQuery(value)
			delete this.query.dictionary_id
			this.getList()
		},
		handleChangebind() {
			this.query.page = 1
			this.getList()
		},
		typeChange() {
			this.getList()
		},
		getTypeListQuery(class_type) {
			this.$post(domain + '/admin/v1/dictionary/deviceTypeNameSelect', { 'class_type': class_type })
				.then(res => {
					let { code, msg, data } = res
					if (code == 0) {
						this.typelsitQuery = data
						if (this.typelsitQuery.length != 0) {
							this.typelsitQuery.unshift({
								'device_type_name': '全部',
								'dictionary_id': '0'
							})
						}
						console.log('typelist', this.typelsitQuery)
					} else {
						this.$message.error(msg, 1.5)
					}
				})
				.catch(err => {
				})
		},
		toRelation(item) {
			this.relationvisible = true
			this.relationInfo = item
			this.relationList()
		},
		relationClose() {
			this.relationvisible = false;
		},
		relationHandleSearch() {
			this.relationquery.page = 1
			this.relationList()
		},
		relationList() {
			console.log('参数打印', this.relationquery)
			this.relationquery.device_id = this.relationInfo.device_id
			this.$post(domain + '/admin/v1/device/relationList', this.relationquery).then(res => {
				if (res.code === 0) {
					this.relationData = res.data.list
					console.log('我是不是空数组', this.relationData)
					this.relationpagetotal = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		callback(key) {
			this.relationquery.page = 1
			this.relationList()
		},
		relationReset() {
			this.relationquery = {
				page: 1,
				limit: this.relationquery.limit,
				type: 1
			}
			this.relationList()
		},
		relationListChange(e) {
			this.relationquery.page = e.current
			this.relationList();
		},
		changeRelation() {
			this.relationquery.type = 2
			this.relationList()
		},
		relation(item) {
			let obj = {
				'relation_id': item.device_id,
				'device_id': this.relationInfo.device_id,
			}
			this.$post(domain + '/admin/v1/device/relation', obj)
				.then(res => {
					let { code, msg, data } = res
					this.confirmLoading = false
					if (code == 0) {
						this.$message.success(msg, 1.5)
						this.relationList()
					} else {
						this.$message.error(msg, 1.5)
					}
				})
				.catch(err => {
					this.confirmLoading = false
				})
		},
		unRelation(item) {
			let obj = {
				'relation_id': item.device_id,
				'device_id': this.relationInfo.device_id,
			}
			this.$post(domain + '/admin/v1/device/unRelation', obj)
				.then(res => {
					let { code, msg, data } = res
					this.confirmLoading = false
					if (code == 0) {
						this.$message.success(msg, 1.5)
						this.relationList()
					} else {
						this.$message.error(msg, 1.5)
					}
				})
				.catch(err => {
					this.confirmLoading = false
				})
		},
		exportDevice() {
			let query = JSON.stringify(this.query)
			window.open(domain + '/admin/v1/device/export?query=' + query + '&token=' + this.$store.state.token);
		}
	},
	computed: {
		isDo: function () {
			return this.form.is_customize == -1;
		}
	}
}
</script>

<style lang="less" scoped>
.img-box {
	overflow: hidden;
	display: inline-block;

	.img-icon {
		transform: translateX(-100px);
		filter: drop-shadow(blue 100px 0)
	}
}

.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4E80F8;
	}

	.title {
		color: #383F50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
